import styles from './EntityValueStep.module.css';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import React, {
    cloneElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { GenericStepProps } from '../../GenericFormEditor/Step/Step';
import { EntityValuePicker } from '../../../@Types/FormTypes/FormStep';
import { calcStepWidth } from '../../GenericFormEditor/StepFunctions';
import RoundedSmartSelect from '../../RoundedSmartSelect/RoundedSmartSelect';
import InputIcon from '../../InputIcon/InputIcon';
import RoundedIconPicker from '../../@Pickers/RoundedIconPicker/RoundedIconPicker';
import IconTypes from '../../../constants/IconTypes';
import EntityValueFilter from './EntityValueFilter/EntityValueFilter';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import EntityPropertyTypes from '../../../constants/EntityPropertyTypes';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import { EntityValueFilters } from '../../../controllers/EntityValuesController/EntityValuesReducer';
import EntityValuesMenu from '../../@Menus/EntityValuesMenu/EntityValuesMenu';
import { EntityValue } from '../../../@Types/EntityValue';
import { EntityValueOptionTypes } from '../../../constants/OptionTypes';
import Option from './Option/Option';
import LocationTypes from '../../../constants/LocationTypes';
import {
    loadEntityValues,
    loadEntityValuesById,
} from '../../../controllers/EntityValuesController/EntityValuesService';
import Loader from '../../Loader/Loader';
import {
    EntityValuePathTypes,
    EntityValueFilterTypes,
} from '../../../constants/FormStepTypes';
import EntityValuePath from './EntityValuePath/EntityValuePath';
import IntegrationTypes from '../../../constants/IntegrationTypes';
import { IntegrationsApi } from '../../../controllers/IntegratrionsController/IntegrationsService';
import { useAppSelector, useIdProject } from '../../../hooks';
import { EntityValueFilterIntegration } from '../../../@Types/Integration';

interface EntityValuePickerStepProps {
    /** children to render */
    children?: React.ReactElement<any, string>;
}

function EntityValuePickerStep<U, L extends GLocation>({
    step,
    size,
    editing,
    children,
    location,
    updateStep,
    ...others
}: GenericStepProps<EntityValuePicker, U, L> &
    EntityValuePickerStepProps): JSX.Element {
    const addBtnRef = useRef<any>();
    const [showAdd, setShowAdd] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const [entityValues, setEntityValues] = useState<Record<
        string,
        EntityValue
    > | null>(null);

    const entity = useAppSelector(
        (state) => state.site.entities[step.idEntity]
    );

    const properties = Object.values(entity.steps).filter(
        (property) =>
            property.type === EntityPropertyTypes.SELECTOR ||
            property.type === EntityPropertyTypes.ENTITY_RELATIONSHIP
    );

    const idProject = useIdProject();

    const { data: integrations } = IntegrationsApi.useLoadIntegrationsQuery({
        idEntity: entity._id,
        idProject,
        type: IntegrationTypes.ENTITY_VALUES_FILTER,
    });

    const newProperty = properties.filter(
        (property) =>
            !step.filters.find((filter) => filter.idProperty === property.id)
    )[0];

    const newIntegration = integrations?.filter(
        (integration) =>
            !step.filters.find(
                (filter) =>
                    filter.type === EntityValueFilterTypes.INTEGRATION &&
                    filter.idIntegration === integration._id
            )
    )[0];

    const canFilter =
        properties.length > 0 || (integrations && integrations?.length > 0);

    const filters = useMemo(() => {
        const filters: EntityValueFilters = {
            values: {},
            relationships: {},
        };
        for (const filter of step.filters) {
            if (filter.type === EntityValueFilterTypes.VALUE) {
                const property = entity.steps[filter.idProperty];
                if (property.type !== EntityPropertyTypes.ENTITY_RELATIONSHIP) {
                    filters.values[filter.idProperty] = filter.value;
                } else {
                    filters.relationships[property.idEntity] = filter.value;
                }
            }
        }
        return filters;
    }, [step.filters]);

    const loadData = async (): Promise<void> => {
        const ids = Object.keys(step.options ?? {});
        const [values, all] = await Promise.all([
            loadEntityValuesById(step.idEntity, ids),
            loadEntityValues(entity, 1, ids.length + 1, filters),
        ]);
        const newEntityValues: Record<string, EntityValue> = {};
        for (const entityValue of values) {
            newEntityValues[entityValue._id] = entityValue;
        }
        setEntityValues(newEntityValues);
        setShowAdd(all.length > ids.length);
    };

    useEffect(() => {
        loadData();
    }, []);

    const mapOptions = (): JSX.Element => {
        if (!entityValues)
            return (
                <div className={styles.loaderContainer}>
                    <Loader size={50} />
                </div>
            );
        return (
            <div className={styles.optionsList}>
                {Object.entries(step.options)
                    .map(([idEntityValue, option]) => ({
                        option,
                        entityValue: entityValues?.[idEntityValue],
                    }))
                    .filter(({ entityValue }) => entityValue)
                    .sort((a, b) =>
                        (
                            a.entityValue.values[entity.idLabelStep] ??
                            entity.name
                        ).localeCompare(
                            b.entityValue.values[entity.idLabelStep] ??
                                entity.name,
                            'es'
                        )
                    )
                    .map(({ option, entityValue }, index) => (
                        <Option
                            key={index}
                            {...others}
                            location={{
                                type: LocationTypes.ENTITY_VALUE,
                                idRootStep: location.idRootStep,
                                idStep: step.id,
                                idEntityValue: entityValue._id,
                                indexStep: null,
                            }}
                            entity={entity}
                            entityValue={entityValue}
                            option={option}
                            updateOption={(option): void => {
                                const tempStep = {
                                    ...step,
                                    options: { ...step.options },
                                };
                                tempStep.options[entityValue._id] = option;
                                updateStep(tempStep);
                            }}
                            handleDeleteOption={(): void => {
                                const tempStep = {
                                    ...step,
                                    options: { ...step.options },
                                };
                                delete tempStep.options[entityValue._id];
                                updateStep(tempStep);
                                if (!showAdd) setShowAdd(true);
                            }}
                            size={size}
                            step={step}
                        />
                    ))}
            </div>
        );
    };

    const elementsToOmit = useMemo(() => {
        if (!entityValues) return [];
        return Object.keys(step.options)
            .map((idEntityValue) => entityValues[idEntityValue])
            .filter((entityValue) => entityValue);
    }, [entityValues, step.options]);

    if (editing) {
        return (
            <React.Fragment>
                {showMenu && entityValues && (
                    <EntityValuesMenu
                        entity={entity}
                        elementsToOmit={elementsToOmit}
                        multiple={false}
                        anchorRef={addBtnRef}
                        filters={filters}
                        handleClose={(): void => {
                            setShowMenu(false);
                        }}
                        handleUpdate={([entityValue], noMore): void => {
                            setEntityValues({
                                ...entityValues,
                                [entityValue._id]: entityValue,
                            });
                            updateStep({
                                ...step,
                                options: {
                                    ...step.options,
                                    [entityValue._id]: {
                                        type: EntityValueOptionTypes.DEFAULT,
                                    },
                                },
                            });
                            setShowMenu(false);
                            if (noMore) setShowAdd(false);
                        }}
                    />
                )}
                <div className={styles.editingContainer}>
                    <div className={styles.inputContainer}>
                        <RoundedTextField
                            label="Etiqueta"
                            value={step.label}
                            onChange={(e): void => {
                                updateStep({ ...step, label: e.target.value });
                            }}
                        ></RoundedTextField>
                    </div>
                    <RoundedTextField
                        label="Descripción"
                        value={step.description ?? ''}
                        onChange={(e): void => {
                            updateStep({
                                ...step,
                                description: e.target.value,
                            });
                        }}
                    ></RoundedTextField>
                    <div className={styles.pathContainer}>
                        {step.path.map((path, index) => {
                            if (
                                path.type === EntityValuePathTypes.VALUE &&
                                path.idEntityValue === null
                            )
                                return (
                                    <div key={path.idEntity + '' + index}></div>
                                );
                            return (
                                <EntityValuePath
                                    path={path}
                                    key={path.idEntity + '' + index}
                                    handleUpdate={(path): void => {
                                        const newPath = [...step.path];
                                        newPath[index] = path;
                                        updateStep({
                                            ...step,
                                            path: newPath,
                                        });
                                    }}
                                />
                            );
                        })}
                    </div>
                    {step.showIcon && (
                        <div className={styles.iconContainer}>
                            <div className={styles.iconLabel}>Icono:</div>
                            <div className={styles.toggleContainer}>
                                <RoundedIconPicker
                                    value={step.icon as IconTypes}
                                    handleUpdate={(icon): void => {
                                        updateStep({
                                            ...step,
                                            icon,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className={styles.optionsLabel}>Opciones: </div>
                    {mapOptions()}
                    {showAdd && (
                        <div
                            className={styles.addOption}
                            onClick={(): void => {
                                setShowMenu(true);
                            }}
                            ref={addBtnRef}
                        >
                            <div className={styles.addIconContainer}>
                                <AddRoundedIcon fontSize="inherit" />
                            </div>
                            <div className={styles.addOptionLbL + ' noselect'}>
                                Agregar Opción
                            </div>
                        </div>
                    )}

                    {canFilter && (
                        <React.Fragment>
                            <div className={styles.filtersLabel}>Filtros:</div>
                            <div className={styles.filtersContainer}>
                                {step.filters.map((filter, index) => (
                                    <EntityValueFilter
                                        key={index}
                                        filter={filter}
                                        idEntity={step.idEntity}
                                        elementsToOmit={step.filters.map(
                                            (filter) =>
                                                filter.type ===
                                                EntityValueFilterTypes.INTEGRATION
                                                    ? filter.idIntegration
                                                    : filter.idProperty
                                        )}
                                        showRequired={false}
                                        handleUpdate={(filter): void => {
                                            const newFilters = [
                                                ...step.filters,
                                            ];
                                            newFilters[index] = filter;
                                            updateStep({
                                                ...step,
                                                filters: newFilters,
                                            });
                                        }}
                                        handleDelete={(): void => {
                                            const newFilters = [
                                                ...step.filters,
                                            ];
                                            newFilters.splice(index, 1);
                                            updateStep({
                                                ...step,
                                                filters: newFilters,
                                            });
                                        }}
                                        context={{
                                            conversation: { idStep: step.id },
                                        }}
                                    />
                                ))}
                                {(newIntegration || newProperty) && (
                                    <div
                                        className={styles.addOption}
                                        onClick={(): void => {
                                            if (newIntegration) {
                                                updateStep({
                                                    ...step,
                                                    filters: [
                                                        ...step.filters,
                                                        {
                                                            idProperty: (
                                                                newIntegration as EntityValueFilterIntegration
                                                            ).idProperty,
                                                            type: EntityValueFilterTypes.INTEGRATION,
                                                            idIntegration:
                                                                newIntegration._id,
                                                        },
                                                    ],
                                                });
                                            } else if (
                                                newProperty?.type ===
                                                EntityPropertyTypes.ENTITY_RELATIONSHIP
                                            ) {
                                                updateStep({
                                                    ...step,
                                                    filters: [
                                                        ...step.filters,
                                                        {
                                                            idProperty:
                                                                newProperty.id,
                                                            type: EntityValueFilterTypes.STEP,
                                                            any: true,
                                                            required: false,
                                                        },
                                                    ],
                                                });
                                            } else {
                                                updateStep({
                                                    ...step,
                                                    filters: [
                                                        ...step.filters,
                                                        {
                                                            idProperty:
                                                                newProperty.id,
                                                            type: EntityValueFilterTypes.VALUE,
                                                            value: [],
                                                        },
                                                    ],
                                                });
                                            }
                                        }}
                                        ref={addBtnRef}
                                    >
                                        <div
                                            className={styles.addIconContainer}
                                        >
                                            <AddRoundedIcon fontSize="inherit" />
                                        </div>
                                        <div
                                            className={
                                                styles.addOptionLbL +
                                                ' noselect'
                                            }
                                        >
                                            Agregar Filtro
                                        </div>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    )}

                    {children &&
                        cloneElement(children, {
                            step,
                            updateStep,
                            uniqueSteps: others.uniqueSteps,
                            updateUniqueSteps: others.updateUniqueSteps,
                        })}
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <div
                style={{
                    width: calcStepWidth(step.size, size),
                    maxWidth: '100%',
                    marginBottom: step.description ? '0px' : '5px',
                    minHeight:
                        step.description || step.required ? '50px' : '38px',
                }}
            >
                <RoundedSmartSelect
                    value={''}
                    fullWidth
                    backgroundColor="var(--primary1)"
                    label={step.label ?? entity.name}
                    required={step.required}
                    helperText={step.description ?? entity.description}
                    height={'31px'}
                    searchable={step.searchable}
                    icon={
                        step.showIcon ? (
                            <InputIcon icon={step.icon ?? entity.icon} />
                        ) : undefined
                    }
                />
            </div>
        );
    }
}

export default React.memo(EntityValuePickerStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.size !== newStep.size ||
        step.label !== newStep.label ||
        step.icon !== newStep.icon ||
        step.showIcon !== newStep.showIcon ||
        step.idEntity !== newStep.idEntity ||
        step.searchable !== newStep.searchable ||
        step.required !== newStep.required ||
        step.path !== newStep.path ||
        step.filters !== newStep.filters ||
        step.description !== newStep.description ||
        step.options !== newStep.options ||
        step.dialogs !== newStep.dialogs
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof EntityValuePickerStep;
