import { AnyAction } from 'redux';
import * as Actions from './SiteActions';
import {
    User,
    Organization,
    Project,
    Classifier,
    Entity,
} from '../../@Types/@Types';
import { ClientEntity } from '../../@Types/Client';
import produce from 'immer';
import { EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import { FullNotification } from '../../@Types/Notification';
import { RootState } from '../../utils/_store';

export interface SiteState {
    /** Current User */
    user: User | null | undefined;
    /** Current Organization, undefined if app has not loaded */
    organization: Organization | undefined;
    /** The user's Projects in the current Organization */
    projects: Record<string, Project>;
    /** id of the current Project, undefined if no project is selected, null if it has not checked */
    idProject: null | string | undefined;
    /** The organization's active Classifiers */
    classifiers: Record<string, Classifier>;
    /** The organization's active Classifier roots */
    classifierRoots: string[];
    /** The user's available Organizations */
    organizations: Organization[];
    /** The current project's active Classifier roots*/
    projectRoots: string[];
    /** The stored entities */
    entities: Record<string, Entity>;
    /** The current ClientInfo Entity */
    clientEntity: ClientEntity | undefined;
    /** The global number of notifications */
    notifications: {
        map: Record<string, true>;
        floating: EntityState<FullNotification>;
    };
}

const floatingNotificationsAdapter = createEntityAdapter<FullNotification>({
    selectId: (notification) => notification._id,
});

export const FloatingNotificationsSelectors =
    floatingNotificationsAdapter.getSelectors(
        (state: RootState) => state.site.notifications.floating
    );

const initialSiteState = {
    user: undefined,
    organization: undefined,
    projects: {},
    idProject: null,
    classifiers: {},
    entities: {},
    classifierRoots: [],
    organizations: [],
    projectRoots: [],
    clientEntity: undefined,
    notifications: {
        map: {},
        floating: floatingNotificationsAdapter.getInitialState(),
    },
};

/**
 * Redux Reducer that handles SiteAction triggers
 * @param state The current state
 * @param action The action that has been triggered
 * @returns the new state
 */
function siteReducer(
    state: SiteState = initialSiteState,
    action: AnyAction
): SiteState {
    if (Actions.loadGuest.match(action)) {
        return {
            ...state,
            user: null,
        };
    } else if (Actions.loadSuccess.match(action)) {
        return {
            ...state,
            user: action.payload.user,
            organization: action.payload.organization,
            entities: action.payload.entities,
            clientEntity: action.payload.entities[
                action.payload.organization.idClientEntity
            ] as ClientEntity,
            classifiers: action.payload.classifiers.elements,
            classifierRoots: action.payload.classifiers.roots,
            projects: action.payload.projects,
            notifications: {
                map: action.payload.notifications,
                floating: floatingNotificationsAdapter.getInitialState(),
            },
        };
    } else if (Actions.loadOrgsSuccess.match(action)) {
        return {
            ...state,
            organizations: action.payload.organizations,
        };
    } else if (Actions.updateUser.match(action)) {
        return {
            ...state,
            user: action.payload,
        };
    } else if (Actions.updateUserPermissions.match(action)) {
        if (!state.user) return state;
        return {
            ...state,
            user: { ...state.user, permissions: action.payload },
        };
    } else if (Actions.changeProject.match(action)) {
        if (state.projects[action.payload.idProject] === undefined) {
            return { ...state, idProject: undefined };
        }
        return {
            ...state,
            projectRoots: state.classifierRoots.filter(
                (root) =>
                    state.classifiers[root].idProject ===
                    action.payload.idProject
            ),
            idProject: action.payload.idProject,
        };
    } else if (Actions.refreshClassifiersSuccess.match(action)) {
        return {
            ...state,
            classifiers: action.payload.elements,
            classifierRoots: action.payload.roots,
            projectRoots: action.payload.roots.filter(
                (root) => state.classifiers[root]?.idProject === state.idProject
            ),
        };
    } else if (Actions.refreshEntitiesSuccess.match(action)) {
        return {
            ...state,
            entities: action.payload,
        };
    } else if (Actions.addClassifiers.match(action)) {
        const classifiers = { ...state.classifiers };
        for (const classifier of action.payload.classifiers) {
            classifiers[classifier._id] = classifier;
        }
        return {
            ...state,
            classifiers,
        };
    } else if (Actions.updateOrganization.match(action)) {
        const organizations = [...state.organizations];
        const organization = action.payload;
        const index = organizations.findIndex(
            (org) => org.idOrganization === organization.idOrganization
        );
        if (index !== -1) {
            organizations[index] = <Organization>organization;
        }
        return <SiteState>{
            ...state,
            organization,
            organizations,
        };
    } else if (Actions.updateOrganizationFailed.match(action)) {
        const organizations = [...state.organizations];
        const organization = action.payload;
        const index = organizations.findIndex(
            (org) => org.idOrganization === organization.idOrganization
        );
        if (index !== -1) {
            organizations[index] = organization;
        }
        return <SiteState>{
            ...state,
            organization,
            organizations,
        };
    } else if (Actions.addNotificationId.match(action)) {
        return produce(state, (state) => {
            state.notifications.map[action.payload] = true;
        });
    } else if (Actions.removeNotificationIds.match(action)) {
        return produce(state, (state) => {
            for (const id of action.payload) {
                delete state.notifications.map[id];
            }
        });
    } else if (Actions.addFloatingNotification.match(action)) {
        return produce(state, (state) => {
            floatingNotificationsAdapter.addOne(
                state.notifications.floating,
                action.payload
            );
        });
    } else if (Actions.removeFloatingNotification.match(action)) {
        return produce(state, (state) => {
            floatingNotificationsAdapter.removeOne(
                state.notifications.floating,
                action.payload
            );
        });
    }

    return state;
}

export default siteReducer;
