import { takeLatest, call, put, fork, all } from 'redux-saga/effects';
import * as actions from './SiteActions';
import * as socketActions from '../SocketsController/SocketsActions';
import { AnyAction } from 'redux';
import { User } from '../../@Types/@Types';
import { loadClassifiers } from './Services/ClassifiersService';
import { loadProjects } from './Services/ProjectsService';
import { loadOrganization } from './Services/OrganizationsService';
import axiosInstance from '../../AxiosAPI';
import jwt from 'jwt-decode';
import { loadEntities } from './Services/EntitiesService';
import LogRocket from 'logrocket';
import { loadNumberOfUnseenNotifications } from './Services/NotificationsMenuService';
import { loadPermissions } from './Services/UserService';

/**
 * Function called when the load action is triggered
 * @param action of the loadTokens
 */
function* load(action: AnyAction): any {
    if (actions.loadTokens.match(action)) {
        try {
            const refreshToken = action.payload.refreshToken;
            localStorage.setItem('refreshToken', refreshToken);
            axiosInstance.defaults.headers['Authorization'] =
                'Bearer ' + action.payload.token;
            localStorage.setItem('token', action.payload.token);

            const user: User = jwt(refreshToken);

            yield put(
                socketActions.initiateSocket({
                    idAgent: user._id,
                    email: user.email,
                    idOrganization: user.idOrganization,
                })
            );

            /** Al the necessary elements are loaded at the same time */
            const [
                organization,
                projects,
                classifiers,
                entities,
                permissions,
                notifications,
            ] = yield all([
                call(loadOrganization),
                call(loadProjects),
                call(loadClassifiers),
                //Loading all entities for now TODO, create a cache for only the used
                call(loadEntities),
                call(loadPermissions),
                call(loadNumberOfUnseenNotifications),
            ]);
            user.permissions = permissions;

            //LOGROCKET
            if (
                process.env.NODE_ENV === 'production' &&
                !organization.idOrganization.startsWith('auto-')
            ) {
                LogRocket.init('63mg8a/eureka-prty1');
                LogRocket.identify(
                    organization.idOrganization + '/' + user._id,
                    {
                        name: user.name,
                        _id: user._id,
                        organization: organization.idOrganization,
                    }
                );

                (window as any).usetifulTags = {
                    userId:
                        user.email === 'andres@capta.co'
                            ? user.idOrganization + '-' + user.email
                            : user.idOrganization + '/' + user.email,
                    organization: user.idOrganization,
                    isOrgAdmin:
                        !!user.permissions.organization.PERMISSIONS + '',
                    isProjectAdmin:
                        !!Object.values(user.permissions.projects).find(
                            (project) => project.PERMISSIONS
                        ) + '',
                    email: user.email,
                    name: user.name,
                };
                const a = document.getElementsByTagName('head')[0];
                const r = document.createElement('script');
                r.async = true;
                r.src = 'https://www.usetiful.com/dist/usetiful.js';
                r.setAttribute('id', 'usetifulScript');
                r.dataset.token = '09b3c5ad6bda184c709e10457ae7ef9d';
                a.appendChild(r);
            }

            yield put(
                actions.loadSuccess({
                    user,
                    organization,
                    projects,
                    classifiers,
                    entities,
                    notifications,
                })
            );
        } catch (error) {
            //TODO
            // eslint-disable-next-line no-console
            console.error(error);
            localStorage.clear();
            setTimeout(() => {
                window.location.reload();
            }, 3000);
            // window.location.reload();
        }
    }
}

/**
 * Function called when the orgs classifiers need refreshing
 * @param action of the loadTokens
 */
function* refreshClassifiers(action: AnyAction): any {
    if (actions.refreshClassifiers.match(action)) {
        try {
            const classifiers = yield call(loadClassifiers);
            yield put(actions.refreshClassifiersSuccess(classifiers));
        } catch (error) {
            //TODO handle errors
            console.error(error);
        }
    }
}

/**
 * Function called when the orgs entities need refreshing
 * @param action of the loadTokens
 */
function* refreshEntities(action: AnyAction): any {
    if (actions.refreshEntities.match(action)) {
        try {
            const entities = yield call(loadEntities);
            yield put(actions.refreshEntitiesSuccess(entities));
        } catch (error) {
            //TODO handle errors
            console.error(error);
        }
    }
}

function* watchLoadTokens(): any {
    yield takeLatest([actions.Types.LOAD], load);
}

function* watchRefreshClassifiers(): any {
    yield takeLatest([actions.Types.REFRESH_CLASSIFIERS], refreshClassifiers);
}

function* watchRefreshEntities(): any {
    yield takeLatest([actions.Types.REFRESH_ENTITIES], refreshEntities);
}

export default [
    fork(watchLoadTokens),
    fork(watchRefreshClassifiers),
    fork(watchRefreshEntities),
];
