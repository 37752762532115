import React, { useEffect, useState, useRef } from 'react';
import styles from './ProfileDetail.module.css';

import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import VanillaToasts from '../../shared/Toast/Toast.js';
import RoundedTextField from '../../shared/RoundedTextField/RoundedTextField';
import { Controller, useForm } from 'react-hook-form';
import {
    getProfile,
    setProfile,
    updateAgentEmailPreferences,
} from '../../controllers/_SiteController/Services/UserService';

import { Agent } from '../../@Types/@Types';
import ImageCard from './ImageCard/ImageCard';
import Loader from '../../shared/Loader/Loader';
import EmailPreferences from './EmailPreferences/EmailPreferences';
import { AgentEmailPreferences } from '../../@Types/Agent';
import ChangePassword from './ChangePassword/ChangePassword';
import Dialog from '../../shared/Dialog/Dialog';
import RoundedPhoneInput from '../../shared/RoundedPhoneInput/RoundedPhoneInput';
function ProfileDetail(): JSX.Element {
    const {
        getValues,
        control,
        formState: { errors, isDirty },
    } = useForm({
        mode: 'onChange',
    });

    const [userData, setUserData] = useState<Agent>();
    const [showDivider, setShowDivider] = useState(false);
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        refresh();
    }, []);

    async function refresh(): Promise<void> {
        const userData = await getProfile();
        setUserData(userData);
        setLoading(false);
    }
    const containerRef = useRef<HTMLDivElement>(null);

    const handleProfileUpdate = async (): Promise<void> => {
        if (userData) {
            try {
                const userObj: Partial<Agent> = { _id: userData._id };
                const values = getValues();
                if (values.name !== userData.name) {
                    userObj.name = values.name;
                }
                if (userData.jobTitle !== values.position) {
                    userObj.jobTitle = values.position;
                }
                if (userData.cel !== values.cel) {
                    userObj.cel = values.cel;
                }
                if (userData.identifier !== values.identifier) {
                    userObj.identifier = values.identifier;
                }
                setLoading(true);
                await setProfile(userObj);
                setLoading(false);
            } catch (error: any) {
                setLoading(false);
                // TODO: Handle errors, manejo de errores
                VanillaToasts.create({
                    title: 'Error:',
                    text: error.response?.data,
                    type: 'error',
                    timeout: 5000,
                    callback: function () {},
                });
            }
        }
    };

    const handlePreferencesUpdate = async (
        emailPreferences: AgentEmailPreferences
    ): Promise<void> => {
        if (userData) {
            try {
                setUserData({ ...userData, emailPreferences });
                await updateAgentEmailPreferences(emailPreferences);
            } catch (error: any) {
                //TODO handle errors
                console.error(error);
            }
        }
    };

    /** Calcs the currentBreakPoint and if is mobile */
    const handleResize = (): void => {
        const currentWidth = containerRef?.current?.offsetWidth;
        if (currentWidth !== undefined) {
            setShowDivider(currentWidth > 840);
        }
    };

    useEffect(() => {
        /** On load add a resize listener to calc the currentBreakpoint */
        window.addEventListener('resize', handleResize);
        handleResize();
        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, [containerRef.current]);

    if (!userData) {
        return <div></div>;
    }
    return (
        <div className={styles.mainContainer} data-testid={'ProfileDetail'}>
            {showPasswordChange && (
                <Dialog
                    open={showPasswordChange}
                    onClose={(): void => setShowPasswordChange(false)}
                >
                    <ChangePassword
                        onClose={(): void => setShowPasswordChange(false)}
                    />
                </Dialog>
            )}
            <div className={styles.topContainer + ' flexbox'}>
                <ImageCard refresh={refresh} userData={userData} />
                <div className={styles.profileCard} ref={containerRef}>
                    {userData && (
                        <div className={styles.profileContent}>
                            <div className={styles.headerCont}>
                                <label className={styles.profileTitle}>
                                    Edita tu perfil
                                </label>
                                <div className={styles.changePasswordCont}>
                                    <RoundedButton
                                        text="Cambiar Clave"
                                        backgroundColor={'var(--secondary)'}
                                        color={'white'}
                                        onClick={(): void => {
                                            setShowPasswordChange(true);
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className={styles.profileContainer + ' flexbox'}
                            >
                                <div className={styles.leftPanel}>
                                    {loading && <Loader size={60} />}
                                    <label className={styles.profileLbl}>
                                        Nombre
                                    </label>
                                    <div
                                        className={styles.inputContainer}
                                        data-testid={'name'}
                                    >
                                        <Controller
                                            name="name"
                                            control={control}
                                            defaultValue={userData.name}
                                            rules={{
                                                required:
                                                    'El nombre es obligatorio',
                                            }}
                                            render={({
                                                field,
                                            }): JSX.Element => (
                                                <RoundedTextField
                                                    {...field}
                                                    helperText={
                                                        errors.name
                                                            ? errors.name
                                                                  .message
                                                            : ''
                                                    }
                                                    error={!!errors.name}
                                                />
                                            )}
                                        />
                                    </div>
                                    <label className={styles.profileLbl}>
                                        Celular
                                    </label>
                                    <div
                                        className={styles.inputContainer}
                                        data-testid={'cel'}
                                    >
                                        <Controller
                                            name="cel"
                                            control={control}
                                            defaultValue={userData.cel}
                                            render={({
                                                field,
                                                fieldState: { error },
                                            }): JSX.Element => (
                                                <RoundedPhoneInput
                                                    {...field}
                                                    fullWidth
                                                    absolute={false}
                                                    helperText={
                                                        error?.message ?? ''
                                                    }
                                                    error={!!error}
                                                />
                                            )}
                                        />
                                    </div>
                                    <label className={styles.profileLbl}>
                                        Cargo
                                    </label>
                                    <div
                                        className={styles.inputContainer}
                                        data-testid={'jobTitle'}
                                    >
                                        <Controller
                                            name="position"
                                            control={control}
                                            defaultValue={userData.jobTitle}
                                            rules={{
                                                required:
                                                    'El cargo es obligatorio',
                                            }}
                                            render={({
                                                field,
                                                fieldState: { error },
                                            }): JSX.Element => (
                                                <RoundedTextField
                                                    {...field}
                                                    helperText={
                                                        error?.message ?? ''
                                                    }
                                                    error={!!error}
                                                />
                                            )}
                                        />
                                    </div>
                                    <label className={styles.profileLbl}>
                                        Identificación
                                    </label>
                                    <div
                                        className={styles.inputContainer}
                                        data-testid={'identifier'}
                                    >
                                        <Controller
                                            name="identifier"
                                            control={control}
                                            defaultValue={userData.identifier}
                                            render={({
                                                field,
                                                fieldState: { error },
                                            }): JSX.Element => (
                                                <RoundedTextField
                                                    {...field}
                                                    helperText={
                                                        error?.message ?? ''
                                                    }
                                                    error={!!error}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div
                                        className={styles.containerSubmitBtn}
                                        data-testid={'submitButton'}
                                    >
                                        <RoundedButton
                                            text="Guardar Cambios"
                                            backgroundColor={'var(--secondary)'}
                                            color={'white'}
                                            disabled={!isDirty}
                                            onClick={handleProfileUpdate}
                                        />
                                    </div>
                                </div>
                                {showDivider && (
                                    <div className={styles.divider}></div>
                                )}
                                <div className={styles.rightPanel}>
                                    <EmailPreferences
                                        handleChange={handlePreferencesUpdate}
                                        preferences={userData.emailPreferences}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* <div className={styles.emailConfsCard}></div> */}
        </div>
    );
}
export default ProfileDetail;
