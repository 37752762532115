import axiosInstance from '../../../AxiosAPI';
import { Agent, User } from '../../../@Types/@Types';
import { AgentEmailPreferences } from '../../../@Types/Agent';
import { EmailPreferences } from '../../../constants/EmailPreferences';

/**
 * Function that handles the app's login
 * @param credentials {email, password} of the user
 * @returns an array with the users Token and RefreshToken
 */
export const login = async (
    credentials: Record<string, string>
): Promise<{ token: string; refreshToken: string }> => {
    let response = await axiosInstance.post('/auth', credentials);
    return {
        token: response?.data?.token,
        refreshToken: response?.data?.refreshToken,
    };
};

/**
 * Function that refreshes the current RefreshToken
 * @param passwordResetToken
 */
export const refreshTokenData = async (): Promise<string> => {
    const response = await axiosInstance.get('/auth/refreshTokenData', {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('refreshToken'),
        },
    });
    return response.data;
};

export const loadPermissions = async (): Promise<User['permissions']> => {
    const response = await axiosInstance.get('/auth/permissions');
    return response.data;
};

/**
 * Function that indicates to the server to delete the refresh token
 */
export const logout = async (): Promise<void> => {
    await axiosInstance.delete('/auth', {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('refreshToken'),
        },
        timeout: 5000,
    });
};

/**
 * Function that triggers a password reset.
 * @param email of the user that wants to change passwords
 */
export const sendResetLink = async (email: string): Promise<void> => {
    await axiosInstance.get('/auth/resetlink' + '?email=' + email); //TODO esto no deberia ser un post?
};

/**
 * Function that handles passwordResetting
 * @param passwordToken the URI Token of the password reset
 * @param password the new password
 */
export const setPassword = async (
    passwordToken: string,
    password: string
): Promise<void> => {
    await axiosInstance.post('/auth/reset', {
        jwtResetToken: passwordToken,
        password: password,
    });
};

/**
 * Function that checks with the server if the passwordResetToken is valid
 * @param passwordResetToken
 */
export const checkLink = async (passwordResetToken: string): Promise<void> => {
    await axiosInstance.get(
        '/auth/checklink?jwtResetToken=' + passwordResetToken
    );
};

/**
 * Function that returns the profile of the actual agent
 */
export const getProfile = async (): Promise<Agent> => {
    const response = await axiosInstance.get('agents/profile');
    return response.data;
};
/**
 * Function that update the profile of the actual agent
 */
export const setProfile = async (user: Partial<Agent>): Promise<void> => {
    await axiosInstance.patch('agents/' + user._id, {
        ...user,
        _id: undefined,
    });
};
/**
 * Function that handles the async update of an agents emailPreferences
 * @param id of the agent to update
 * @param emailPreferences Record of values to update
 */
export const updateAgentEmailPreferences = async (
    emailPreferences: AgentEmailPreferences
): Promise<void> => {
    const preferences: AgentEmailPreferences = {};
    for (const pref of Object.values(EmailPreferences)) {
        preferences[pref] = emailPreferences[pref];
    }
    await axiosInstance.patch('agents/emailPreferences', preferences);
};

/**
 * Function that update the profile of the actual agent
 */
export const setImgProfile = async (user: {
    img: File;
    _id: string;
}): Promise<void> => {
    const fd = new FormData();
    if (user.img.type.includes('image')) {
        fd.append('img', user.img, user.img.name);
    } else {
        throw { response: { data: 'Formato de imagen incorrecto' } };
    }
    await axiosInstance.patch('agents/' + user._id + '/img', fd, {
        timeout: 30000,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

/**
 * Function that update the profile of the actual agent
 */
export const updatePassword = async (
    password: string,
    newPassword: string
): Promise<void> => {
    await axiosInstance.patch('/auth/resetPassword', {
        password: password,
        newPassword: newPassword,
    });
};
export default {
    login,
    logout,
    sendResetLink,
    updateAgentEmailPreferences,
    setPassword,
    checkLink,
    getProfile,
    setProfile,
    updatePassword,
    loadPermissions,
    refreshTokenData,
};
