import {
    takeLatest,
    call,
    fork,
    take,
    put,
    takeEvery,
    select,
    cancelled,
    delay,
} from 'redux-saga/effects';
import * as actions from './SocketsActions';
import * as actionActions from '../TicketsController/Actions/ActionsActions';
import * as ticketActions from '../TicketsController/TicketsActions';
import * as siteActions from '../_SiteController/SiteActions';
import * as NotificationsMenuActions from '../NotificationsMenuController/NotificationsMenuActions';
import {
    createSocket,
    onMessage,
    removeListener,
    onClose,
} from './SocketsService';
import { AnyAction } from 'redux';
import { EventChannel, eventChannel } from 'redux-saga';
import { RootState } from '../../utils/_store';
import { Ticket } from '../../@Types/TicketTypes/Ticket';
import SOCKET_MESSAGE_TYPES from '../../constants/Sockets/SocketMessageTypes';
import axiosInstance from '../../AxiosAPI';
import SocketTicketActionEventType from '../../constants/Sockets/SocketTicketActionEventType';
import { addPendingReply } from '../../utils/ReplyCache';
import { User } from '../../@Types/User';
import ActionTypes from '../../constants/ActionTypes';
import { updateUserPermissions } from '../_SiteController/SiteActions';
import { refreshCurrent } from '../TicketsController/TicketsActions';
import StockNotificationsTypes from '../../constants/Sockets/StockNotificationTypes';
import {
    fetchFullStockNotification,
    markAsRead,
} from '../NotificationsMenuController/NotificationsMenuService';
import UserService from '../_SiteController/Services/UserService';

/**
 * Function to get the current ticket shown in the tickets page
 * @param state the application redux state
 */
const getCurrentTicket = (state: RootState): Ticket | undefined => {
    if (state.ticketsPage.selectedElement !== undefined) {
        return state.ticketsPage.elements[
            state.ticketsPage.order[state.ticketsPage.selectedElement]
        ];
    } else {
        return undefined;
    }
};

const getUser = (state: RootState): User | undefined | null => {
    return state.site.user;
};
/**
 * Function that creates a saga channel, and emit events when the socket receive incoming messages
 * @param socket the socket that is going to emit data in the channel
 */
const createSocketChannel = (socket: WebSocket): EventChannel<any> =>
    eventChannel((emit) => {
        const handler = (data: Record<string, any>): void => {
            emit(data);
        };
        onMessage(socket, handler);
        return (): void => {
            removeListener(socket, handler);
        };
    });
/**
 * Function that creates a "listener" of a close socket event
 * @param socket the socket where the listener is going to be created
 */
const disconnect = (socket: WebSocket): Promise<CloseEvent> => {
    return new Promise((resolve) => {
        onClose(socket, (event: CloseEvent) => {
            resolve(event);
        });
    });
};
/**
 * Function that close all socket connections, when a closed event is received
 * @param socket the socket
 * @param socketChannel the channel where socket events are putted in
 */
const listenDisconnectSocket = function* (
    socket: WebSocket,
    socketChannel: EventChannel<any>
): any {
    yield call(disconnect, socket);
    yield put(
        actions.closeSocket({
            socket: socket,
            socketChannel: socketChannel,
        })
    );
};
/**
 * Function that close all socket connections, when a closed event is received
 * @param socket the socket
 * @param socketChannel the channel where socket events are putted in
 */
const manageSocket = function* (action: AnyAction): any {
    while (true) {
        yield call(
            connectSocket,
            action.payload.idAgent,
            action.payload.email,
            action.payload.idOrganization
        );
        yield delay(5000);
    }
};
/**
 * Function called when a closed action is received (Logout or timeout)
 * @param action of type closeSocket
 */
function* closeConnections(action: AnyAction): any {
    action.payload.socketChannel.close();
    action.payload.socket.close();
}
/**
 * Function called to manage the socket connection (Create the socket, close the socket, create a channel and handle the channel events)
 * @param idAgent
 * @param email
 * @param idOrganization
 */
function* connectSocket(
    idAgent: string,
    email: string,
    idOrganization: string
): any {
    let socket;
    let socketChannel;
    try {
        socket = (yield call(
            createSocket,
            idAgent,
            email,
            idOrganization
        )) as unknown as WebSocket;
        socketChannel = yield call(createSocketChannel, socket);
        yield fork(listenDisconnectSocket, socket, socketChannel);
        while (true) {
            const payload = yield take(socketChannel);
            yield put(actions.receiveMessage(payload));
        }
    } catch (error) {
        console.error(error);
    } finally {
        if (yield cancelled()) {
            if (socketChannel) {
                socketChannel.close();
            }
            if (socket) {
                socket.close();
            }
        }
    }
}
/**
 * Function called when a message is received by the socket
 * @param action of type receiveMessage
 */
function* manageIncomingMessages(action: AnyAction): any {
    try {
        switch (action.payload.type) {
            case SOCKET_MESSAGE_TYPES.TICKET_ACTION:
                const actualTicket = yield select(getCurrentTicket);
                const idTicket = action.payload.idTicket;
                if (actualTicket && actualTicket._id === idTicket) {
                    switch (action.payload.eventType) {
                        case SocketTicketActionEventType.UPDATE:
                            const update = action.payload.update;
                            yield put(
                                actionActions.updateAction({
                                    idAction: action.payload.idAction,
                                    update,
                                })
                            );
                            break;
                        case SocketTicketActionEventType.ADD:
                            const user = yield select(getUser);
                            const newAction = action.payload.action;
                            if (newAction.type === ActionTypes.TEMP_REPLY) {
                                addPendingReply(
                                    newAction.idProject,
                                    newAction.idTicket,
                                    newAction._id,
                                    user._id === newAction.agent?._id
                                );
                            }
                            yield put(actionActions.appendAction(newAction));
                            break;
                        case SocketTicketActionEventType.REMOVE:
                            yield put(
                                actionActions.removeAction(
                                    action.payload.idAction
                                )
                            );
                            break;
                        case SocketTicketActionEventType.REFRESH:
                            yield put(refreshCurrent(true));
                            break;
                        default:
                            break;
                    }
                }
                break;
            case SOCKET_MESSAGE_TYPES.REFRESH_USER:
                const permissions = yield call(UserService.loadPermissions);
                yield put(updateUserPermissions(permissions));
                break;
            case SOCKET_MESSAGE_TYPES.NOTIFICATION:
                switch (action.payload.notification.type) {
                    case StockNotificationsTypes.ACTION:
                        yield put(NotificationsMenuActions.needReset());
                        const actualTicket = yield select(getCurrentTicket);
                        if (
                            actualTicket?._id !==
                            action.payload.notification.idTicket
                        ) {
                            const fullNotification = yield call(
                                fetchFullStockNotification,
                                action.payload.notification._id
                            );
                            yield put(
                                siteActions.addFloatingNotification(
                                    fullNotification
                                )
                            );
                            yield put(
                                ticketActions.addNotification({
                                    idTicket:
                                        action.payload.notification.idTicket,
                                    idNotification:
                                        action.payload.notification._id,
                                })
                            );
                            yield put(
                                siteActions.addNotificationId(
                                    action.payload.notification._id
                                )
                            );
                        } else if (actualTicket) {
                            yield put(
                                ticketActions.removeNotification({
                                    idTicket: actualTicket._id,
                                    idNotification:
                                        action.payload.notification._id,
                                })
                            );
                            yield call(
                                markAsRead,
                                action.payload.notification._id
                            );
                        }

                        break;
                }
                break;
            default:
                if (action.payload.idSocket) {
                    axiosInstance.defaults.headers['erk-idsocket'] =
                        action.payload.idSocket;
                }
                break;
        }
    } catch (error) {
        console.error(error);
    }
}

function* watchInitiateSocket(): any {
    yield takeLatest([actions.Types.INITIATE_SOCKET], manageSocket);
}
function* watchReceiveMessage(): any {
    yield takeEvery([actions.Types.RECEIVE_MESSAGE], manageIncomingMessages);
}
function* watchCloseSocket(): any {
    yield takeEvery([actions.Types.CLOSE_SOCKET], closeConnections);
}
export default [
    fork(watchInitiateSocket),
    fork(watchReceiveMessage),
    fork(watchCloseSocket),
];
