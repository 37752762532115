import { createAction } from '@reduxjs/toolkit';
import {
    User,
    Organization,
    Project,
    Classifier,
    Entity,
} from '../../@Types/@Types';
import { FullNotification } from '../../@Types/Notification';

export const Types = {
    LOAD: 'SITE/LOAD',
    LOADGUEST: 'SITE/LOAD_GUEST',
    LOAD_SUCCESS: 'SITE/LOAD_SUCCESS',
    LOAD_ORGS_SUCCESS: 'SITE/LOAD_ORGS_SUCCESS',
    UPDATE_ORG: 'SITE/UPDATE_ORG',
    UPDATE_USER: 'SITE/UPDATE_USER',
    UPDATE_USER_PERMISSIONS: 'SITE/UPDATE_USER_PERMISSIONS',
    UPDATE_ORG_FAILED: 'SITE/UPDATE_ORG_FAILED',
    CHANGEPROJECT: 'SITE/CHANGEPROJECT',
    REFRESH_CLASSIFIERS: 'SITE/REFRESH_CLASSIFIERS',
    REFRESH_CLASSIFIERS_SUCCESS: 'SITE/REFRESH_CLASSIFIERS_SUCCESS',
    REFRESH_ENTITIES: 'SITE/REFRESH_ENTITIES',
    REFRESH_ENTITIES_SUCCESS: 'SITE/REFRESH_ENTITIES_SUCCESS',
    ADD_CLASSIFIERS: 'SITE/ADD_CLASSIFIERS',
    ADD_NOTIFICATION_ID: 'SITE/ADD_NOTIFICATION_ID',
    REMOVE_NOTIFICATION_IDS: 'SITE/REMOVE_NOTIFICATION_IDS',
    ADD_NOTIFICATION: 'SITE/ADD_NOTIFICATION',
    REMOVE_NOTIFICATION: 'SITE/REMOVE_NOTIFICATION',
};

/**
 * Called when the app is first loaded and tokens were found
 */
export const loadTokens = createAction<{
    token: string;
    refreshToken: string;
}>(Types.LOAD);

/**
 * Called when no tokens where found.
 */
export const loadGuest = createAction(Types.LOADGUEST);

/**
 * Called after the first load, includes all the necesary info for the first load.
 */
export const loadSuccess = createAction<{
    user: User;
    organization: Organization;
    projects: Record<string, Project>;
    classifiers: {
        elements: Record<string, Classifier>;
        roots: string[];
    };
    entities: Record<string, Entity>;
    notifications: Record<string, true>;
}>(Types.LOAD_SUCCESS);

/**
 * Called when the orgs are async loaded
 */
export const loadOrgsSuccess = createAction<{
    organizations: Organization[];
}>(Types.LOAD_ORGS_SUCCESS);

/**
 * Called when the current project needs to change
 */
export const updateUser = createAction<User>(Types.UPDATE_USER);

/**
 * Called when the current project needs to change
 */
export const updateUserPermissions = createAction<User['permissions']>(
    Types.UPDATE_USER_PERMISSIONS
);

/**
 * Called when the user changes the Organization
 */

export const updateOrganization = createAction<Organization>(Types.UPDATE_ORG);

export const updateOrganizationFailed = createAction<Organization>(
    Types.UPDATE_ORG_FAILED
);

/**
 * Called when the current project needs to change
 */
export const changeProject = createAction<{
    idProject: string;
}>(Types.CHANGEPROJECT);

/**
 * Called when the entities need refreshing
 */
export const refreshEntities = createAction(Types.REFRESH_ENTITIES);

/**
 * Called when the entities need to change in the reducer
 */
export const refreshEntitiesSuccess = createAction<Record<string, Entity>>(
    Types.REFRESH_ENTITIES_SUCCESS
);

/**
 * Called when the classifiers need refreshing
 */
export const refreshClassifiers = createAction(Types.REFRESH_CLASSIFIERS);

/**
 * Called when the classifiers need to change in the reducer
 */
export const refreshClassifiersSuccess = createAction<{
    elements: Record<string, Classifier>;
    roots: string[];
}>(Types.REFRESH_CLASSIFIERS_SUCCESS);

/** Called to add new classifiers to the store */
export const addClassifiers = createAction<{
    classifiers: Classifier[];
}>(Types.ADD_CLASSIFIERS);

/** Called to change the notifications count, the number can be positive or negative */
export const addNotificationId = createAction<string>(
    Types.ADD_NOTIFICATION_ID
);

export const removeNotificationIds = createAction<string[]>(
    Types.REMOVE_NOTIFICATION_IDS
);

export const addFloatingNotification = createAction<FullNotification>(
    Types.ADD_NOTIFICATION
);

export const removeFloatingNotification = createAction<string>(
    Types.REMOVE_NOTIFICATION
);
